<template>
  <div id="depositFunds">
    <div class="content-box">
      <div class="inner">
        <!-- payments -->
        <component :is="currentComponent"></component>

        <!-- middle blue alert message -->
        <div
          v-if="showMiddleDeposit50Msg"
          class="alert-container"
          :class="{ mobile: alertContainerWidth < 1050 }"
          v-resizeObserver="{
            openResize: true,
            handler: observerCallBack
          }"
        >
          <div class="desc">
            {{ $t('deposit.alert.depositBonus50') }}
          </div>
          <el-button class="btn-default alert-btn" @click="directToDeposit50">
            {{ $t('promotion.depositBonus.link') }}
          </el-button>
        </div>

        <!-- info -->
        <div class="info-box">
          <ul v-html="$t('deposit.default.disclaimer', { platform: $config.info.fullName })"></ul>
        </div>
      </div>
    </div>

    <!-- dialog -->
    <ConditionDialog :visible.sync="showDeposit50Dialog" dialogHeightAuto isSuccess @closCbDialog="closCbDialog">
      <template v-slot:content>
        <p class="text-result">
          {{ $t('deposit.alert.depositBonus50') }}
        </p>
        <el-button class="btn-blue submit-btn" @click="directToDeposit50">
          {{ $t('promotion.depositBonus.link') }}
        </el-button>
      </template>
    </ConditionDialog>
  </div>
</template>

<script>
import ConditionDialog from '@/components/dialog/ConditionDialog.vue';
import { PROMOTION_TYPE } from '@/constants/promotion';
import { apiCampaignOptStatus } from '@/resource';

export default {
  name: 'DepositFunds',
  components: { ConditionDialog },
  data() {
    return {
      showDeposit50Dialog: false,
      showMiddleDeposit50Msg: false,
      alertContainerWidth: 0
    };
  },
  computed: {
    currentComponent() {
      return () => this.$config.pageLoader(this.regulator, 'Deposit');
    },
    isShowFirstPromotionPopup() {
      return this.$store.state.payment.isShowFirstPromotionPopup;
    },
    deposit50CampaignID() {
      let campaign = this.$store.state.promotion.eligibleCampaigns.find(
        c => c.campaignType === PROMOTION_TYPE.DEPOSIT_BONUS && c.id === 6
      );
      return campaign ? campaign.id : null;
    }
  },
  watch: {
    showDeposit50Dialog: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.showMiddleDeposit50Msg = false;
        }
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch('payment/actionPaymentList');
      this.$store.dispatch('payment/actionRestrictDeposit');

      // ACM-9675 needs to check deposit 50% opt status every time for ensuring the follow case can show popup.
      // *user case:
      //     step1. The user is opt-in a promotion whitch excluded Desposit 50% promotion.
      //     step2. User goes to /DepositFunds (popup not shown).
      //     step3. User opt-out the current promotion (allowed to opt-in Deposit 50%).
      //     step4. User goes to /DepositFunds (popup should showup).
      if (!this.deposit50CampaignID) return;
      apiCampaignOptStatus(this.deposit50CampaignID).then(res => {
        if (!res.data.data && this.deposit50CampaignID) {
          if (!this.isShowFirstPromotionPopup) {
            this.showDeposit50Dialog = false;
            this.showMiddleDeposit50Msg = true;
          } else {
            this.showDeposit50Dialog = true;
          }
        }
      });
    },
    closCbDialog() {
      this.showDeposit50Dialog = false;
      this.showMiddleDeposit50Msg = true;
      this.$store.dispatch('payment/actionSetIsShowFirstPromotionPopup', false);
    },
    observerCallBack(width) {
      this.alertContainerWidth = width;
    },
    directToDeposit50() {
      if (!this.deposit50CampaignID) return;
      this.$router.push({ name: 'dynamicDepositBonus', params: { campaignId: this.deposit50CampaignID } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/logo.scss';
@import '@/assets/css/pages/depositFunds.scss';

@media (min-width: 550px) {
  /deep/ .common-dialog-wrapper .el-dialog__body p {
    padding: 0 16% !important;
  }
}

.text-result {
  margin-top: 12px;
  margin-bottom: 24px;
}
.text-link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline !important;
  }
}
.submit-btn {
  margin-bottom: 16px;
  min-width: 180px;
}

.alert-container {
  background-color: $blue;
  min-height: 60px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .desc {
    color: $white;
    font-size: 22px;
  }
  .alert-link {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }
  .alert-btn {
    text-transform: capitalize;
    font-weight: 600;
    padding: 12px 20px;
  }

  &.mobile {
    flex-direction: column;
    .desc {
      font-size: 16px;
    }
    .alert-btn {
      margin-top: 12px;
    }
  }
}
</style>
